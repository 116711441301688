import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_card = _resolveComponent("van-card");
  const _component_van_checkbox = _resolveComponent("van-checkbox");
  const _component_van_checkbox_group = _resolveComponent("van-checkbox-group");
  const _component_van_submit_bar = _resolveComponent("van-submit-bar");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_checkbox_group, {
    class: "card-goods",
    modelValue: $data.checkedGoods,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.checkedGoods = $event)
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.goods, item => {
      return _openBlock(), _createBlock(_component_van_checkbox, {
        class: "card-goods__item",
        key: item.id,
        name: item.id
      }, {
        default: _withCtx(() => [_createVNode(_component_van_card, {
          title: item.title,
          desc: item.desc,
          num: item.num,
          price: $options.formatPrice(item.price),
          thumb: item.thumb
        }, null, 8, ["title", "desc", "num", "price", "thumb"])]),
        _: 2
      }, 1032, ["name"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_van_submit_bar, {
    price: $options.totalPrice,
    disabled: !$data.checkedGoods.length,
    "button-text": $options.submitBarText,
    onSubmit: $options.onSubmit
  }, null, 8, ["price", "disabled", "button-text", "onSubmit"])]);
}